#top{
	
	.topProArea{
		overflow: hidden;
		padding: 50px 0;
		@include maxW('max-mbl'){
			padding: 20px 0;
		}
		p{
			font-size: 1.3rem;
			@include maxW('max-mbl'){
				font-size: 1.1rem;
			}
		}
		
		.topProBox1 >.topDescBox::after{
			background: #00aef2;
		}
		.topProBox2 >.topDescBox::after{
			background: #f08204;
		}
		.topProBox3 >.topDescBox::after{
			background: #221816;
		}
		.topProBox4 >.topDescBox::after{
			background: #1e2777;
		}
		@include minW('min-tblt'){
			.stantBox{
				width: 890px;
				max-width: 100%;
				@include flxBasic($ai:stretch,$jc:flex-start);
				>.topImgBox{
					width: 66%;
					position: relative;
					z-index: 300;
				}
				>.topDescBox{
					width: calc(34% + 20px);
					margin: 25px 0;
					z-index: 200;
					position: relative;
					>div{
						background: $siteCol-lightgrey;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 100;
						padding: 30px 40px;
					}
					&::after{
						content: "";
						position: absolute;
						top: 7px;
						width: 100%;
						height: 100%;
						z-index: 10;
					}
				}
				transform: rotate(-10deg);
				.topDescBox>div>div{
					transform: rotate(10deg);
					padding-top: 10px;
				}
			}
			.topProBox1{
				margin-top: 100px;
				margin-left: -50px;
				margin-right: auto;
				>.topDescBox{
					margin-left: -20px;
					>div{
						border-radius: 0 0 30%/50% 0;
					}
					&::after{
						left: 4px;
						border-radius: 0 100px 100px 0;
					}
				}
			}
			.topProBox2{
				margin-top: 70px;
				margin-left: auto;
				margin-right: -50px;
				>.topImgBox{
					order: 1;
				}
				>.topDescBox{
					margin-right: -20px;
					>div{
						border-radius: 0 0 0 30%/50%;
					}
					&::after{
						left: -4px;
						border-radius: 100px 0 0 100px;
					}
				}
			}
			.circleBox{
				position: relative;
				width: 320px;
				max-width: 35%;
				>.topImgBox{
					position: absolute;
					z-index: 300;
				}
				>.topDescBox{
					width: 100%;
					&::before{
						content: "";
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						width: 100%;
						padding-top: 100%;
						border-radius: 50%;
						background: $siteCol-lightgrey;
						z-index: 100;
					}
					>div{
						position: relative;
						z-index: 200;
					}
					&::after{
						content: "";
						position: absolute;
						z-index: 10;
						width: calc(100% + 10px);
						padding-top: calc(100% + 10px);
						border-radius: 50%;
						transform: translateX(-50%);
					}
				}
			}
			.topProBox3{
				margin-top: 70px;
				margin-left: auto;
				>.topImgBox{
					width: 150%;
					max-width: 150%;
					right: 15%;
					top: 100%;
					transform: rotate(-10deg);
				}
				>.topDescBox{
					&::before{
						top: 0;
					}
					>div{
						padding: 20% 45px 50px;
					}
					&::after{
						top: -7px;
						left: calc(50% + 4px);
					}
				}
			}
			.topProBox4{
				margin-top: 120px;
				margin-right: auto;
				margin-bottom: 100px;
				>.topImgBox{
					width: 85%;
					max-width: 85%;
					right: -15%;
					bottom: 90%;
				}
				>.topDescBox{
					&::before{
						bottom: 60%;
						transform: translate(-50%,50%);
					}
					>div{
						padding: 0 45px 20px;
					}
					&::after{
						bottom: calc(60% - 7px);
						left: calc(50% - 4px);
						transform: translate(-50%,50%);
					}
				}
			}
		}
		
		@include maxW('max-tblt'){
			.stantBox,.circleBox{
				width: 100%;
				>.topImgBox{
					width: 486px;
					max-width: 90%;
					z-index: 300;
					position: relative;
					display: block;
				}
				>.topDescBox{
					width: 80%;
					position: relative;
					margin-left: auto;
					margin-right: auto;
					margin-top: -2vw;
					>div{
						position: relative;
						background: $siteCol-lightgrey;
						padding: 30px;
						z-index: 100;
					}
					&::after{
						content: "";
						position: absolute;
						top: 5px;
						width: 100%;
						height: 100%;
						z-index: 10;
					}
				}
				transform: none;
				.topDescBox>div>div{
					transform: none;
				}
			}
			.topProBox1,.topProBox3{
				margin: 10vw auto;
				>.topImgBox{
					margin-left: 0;
					margin-right: auto;
				}
				>.topDescBox{
					margin-left: -30px;
					margin-right: auto;
					&::after{
						left: 5px;
						border-radius: 0 0 22.5px 0;
					}
					>div{
						border-radius: 0 0 20px 0;
					}
				}
			}
			.topProBox2,.topProBox4{
				margin: 10vw auto;
				>.topImgBox{
					order: 0;
					margin-left: auto;
					margin-right: 0;
				}
				>.topDescBox{
					margin-left: auto;
					margin-right: -30px;
					&::after{
						left: -5px;
						border-radius: 0 0 0 22.5px;
					}
					>div{
						border-radius: 0 0 0 20px;
					}
				}
			}
			.topProBox1{
				margin-top: 15vw;
			}
			.topProBox4{
				>.topImgBox{
					width: 274px;
					max-width: 50%;
					margin: 0 auto;
				}
			}
			@include maxW('max-mbl'){
				.stantBox,.circleBox{
					>.topDescBox{
						width: 70%;
						>div{
							padding: 20px;
						}
					}
				}
				.topProBox1,.topProBox3{
					>.topImgBox{
						margin-left: -40px;
					}
					>.topDescBox{
						margin-left: -20px;
					}
				}
				.topProBox2{
					>.topImgBox{
						margin-left: auto;
						margin-right: -40px;
						@media only screen and (max-width: 500px) { // スマホ表示のバグ修正
							margin-left: calc(15% + 40px);
						}
					}
					>.topDescBox{
						margin-right: -20px;
					}
				}
				.topProBox4{
					>.topImgBox{
						margin-right: 4vw;
					}
					>.topDescBox{
						margin-right: -20px;
						margin-top: -5vw;
					}
				}
			}
		}
		
		
		.topProBtnBox{
			text-align: center;
			a{
				@include overTrans();
				position: relative;
				display: inline-block;
				background: $siteCol-red;
				color: $siteCol-white;
				width: 400px;
				height: 70px;
				margin: 70px auto;
				font-size: 2.0rem;
				font-weight: 900;
				border-radius: 35px;
				@include centerCenter;
				&::after{
					content: "＞";
					position: absolute;
					right: 35px;
					top: 50%;
					transform: translateY(-50%);
				}
				@include maxW('max-mbl'){
					font-size: 1.6rem;
					width: 80%;
					margin: 50px auto 30px;
					height: 60px;
				}
			}
			a:hover{
				@include minW('min-mbl'){
					transform: scale(1.05);
					box-shadow: 0 0 20px rgba(0,0,0,.4);
					&::after{
						right: 30px;
					}
				}
			}
		}
	}
	
	.topBtnArea{
		background: $siteCol-water;
		padding: 70px 0 60px;
		@include maxW('max-tblt'){
			padding: 30px 0 0;
		}
		.topBtnBox{
			@include flxBasic();
			a{
				width: calc((100% - 70px)/3);
				padding-top: calc((100% - 70px)/3);
				border: 5px solid $siteCol-white;
				position: relative;
				overflow: hidden;
				@include maxW('max-tblt'){
					width: 100%;
					border: 4px solid $siteCol-white;
					&:not(:last-child){
						margin-bottom: 15px;
					}
				}
				@include maxW('max-mbl'){
					padding-top: 40%;
					border: 3px solid $siteCol-white;
				}
				img{
					position: absolute;
					width: auto;
					max-width: none;
					height: 100%;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					z-index: 100;
					@include maxW('max-tblt'){
						width: 100%;
						height: auto;
					}
				}
				h4{
					position: absolute;
					width: 100%;
					text-align: center;
					text-shadow: 0 0 7px #000;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					color: $siteCol-white;
					z-index: 300;
					font:{
						size: 2.5rem;
						weight: 900;
					}
					@include maxW('max-mbl'){
						font-size: 2.0rem;
					}
				}
				&::before{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,.4);
					z-index: 200;
				}
				&::after{
					content: "";
					position: absolute;
					display: block;
					left: 50%;
					bottom: 20px;
					transform: translateX(-50%);
					background: url(../img/top_btn_arrow.png);
					background-size: 100%;
					width: 21px;
					height: 21px;
					z-index: 300;
					@include maxW('max-mbl'){
						width: 16px;
						height: 16px;
					}
				}
				>*,&::after,&::before{
					@include overTrans;
				}
			}
			a:hover{
				@include minW('min-mbl'){
					img{
						transform: translate(-50%,-50%) scale(1.05);
					}
					&::before{
						opacity: .4;
					}
				}
			}
		}
	}
}